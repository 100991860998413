



html {
	margin: 0;
	padding: 0;
}
:root{
	--shadow-color: 184deg 100% 13%;
	--shadow-elevation-low:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);
}

.shadow-elevation-low {
	box-shadow: var(--shadow-elevation-low);
}

.App {
	background: linear-gradient(-45deg, #001f3f, #34495e, #2c3e50);
	animation: gradient 10s ease infinite;
	background-size: 200% 200%;
	height: 100vh;
	margin: 0;
  }

  @media screen and (max-width: 600px) {
	.App {
	  background: linear-gradient(-45deg, #001f3f, #34495e, #2c3e50);
	  animation: gradient 10s ease infinite;
	  background-size: 200% 200%;
	  height: 200vh;
	  width: 100vw;
	  margin: 0;

	
  }
}





.App-header {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;	
  align-items: center;
  justify-content: space-around;
  color: white;
}

.btn-add{
	background-color: #006b3e;
	border: 0px !important;
	color: white !important;
	padding: 10px;
}

.btn-add:hover{
	background-color: #006b3e !important;
}

@media screen and (max-width: 600px) {
	.App-header {
	  height: 200vh;
	  width: 100vw;
	  display: flex;
	  flex-direction: column-reverse;
	  align-items: center;
	  justify-content: space-around;
	}
  }
	.card{
		background-color: #333342;
		border-radius: 1rem;
		padding: 1rem;
	}

	.imss-header{
		background:linear-gradient(-45deg, #006b3e, #000, #006b3e, #006b3e);
		animation: gradient 10s ease infinite;
		background-size: 400% 400%;
		color: white;
		user-select: none;
	}

	.impuls-header{
		background:linear-gradient(-45deg, #1D2B64, #000, #1D2B64, #0c222e);
		animation: gradient 10s ease infinite;
		background-size: 400% 400%;
		color: white;
		user-select: none;
	}
	.protecc-header {
		background: linear-gradient(-45deg, #ad32ff, #000, #4d0057, #2e0c29);
		animation: gradient 30s ease infinite;
		background-size: 400% 400%;
		color: white;
		user-select: none;
	  }
	.main-header {
		background: linear-gradient(-45deg, #051721, #004445, #051721, #051721);
		animation: gradient 30s ease infinite;
		background-size: 400% 400%;
		color: white;
		user-select: none;
	  }
		.impulstpv-header {
			background: linear-gradient(-45deg, #051721, #008789, #0e3a52, #081c27);
			animation: gradient 30s ease infinite;
			background-size: 400% 400%;
			color: white;
			user-select: none;
		  }
	::placeholder {
		color: white;
		opacity: 1;
	}

	@keyframes gradient {
		0% {
		  background-position: 0% 50%;
		}
		25% {
		  background-position: 100% 0%;
		}
		50% {
		  background-position: 0% 50%;
		}
		75% {
		  background-position: 100% 0%;
		}
		100% {
		  background-position: 0% 50%;
		}
	  }

	  /* Añade estos estilos globales en tu archivo CSS */
/* Estilos para WebKit (Chrome, Safari, etc.) */
*::-webkit-scrollbar {
	width: 8px; /* o el grosor que prefieras */
  }
  
  *::-webkit-scrollbar-track {
	background: #333342; /* Color de la pista del scrollbar */
  }
  
  *::-webkit-scrollbar-thumb {
	background-color: #fff; /* Color del "pulgar" del scrollbar */
	border-radius: 4px; /* Redondea las esquinas */
	border: 2px solid #333342; /* Crea un borde alrededor del pulgar */
  }
  
  /* Estilos para Firefox */
  * {
	scrollbar-color: #fff #333342; /* thumb and track color */
	scrollbar-width: thin; /* 'auto' or 'thin' */
  }

.bento-container{
  display: grid;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  max-height: 600px;
  gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(5, 300px);
  padding: 0.5rem;
}

.bento-item-container{
	background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,83,1) 26%, rgba(0,212,255,0.07326680672268904) 80%);
	height: 100%;
	border-radius: 1rem;
	overflow: hidden;

}
.proximamente{
	color: white;
	background-color: #01002c;
	padding: .3rem;
	font-size: small;
	border-radius: .5rem;
	justify-content: center;
	text-align: center;
	margin-right: auto;
	border: .2px solid gray;
}




.bento-item{
  border-radius: 1rem;
  height: 100%;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
	.bento-container{
		grid-template-columns: repeat(3, 1fr);
	}
	
}

@media screen and (min-width:1024px){
	.bento-container{
		grid-template-rows: 4fr 3fr 3fr;
	}
	.bento-item{
		&:nth-of-type(1) {
			grid-row: 1/3;
		  }
		  &:nth-of-type(2) {
			grid-row: 3/4;
		  }
		  &:nth-of-type(5) {
			grid-column: 2/4;
			grid-row: 2/4;
		  }
	}
}